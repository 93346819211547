@import url('//fonts.googleapis.com/css?family=Roboto%3A300%2C400%2C500%2C700%2C900&#038;display=swap&#038;ver=1.0');

html {
  font-family: 'Roboto', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0;
}

.book-consultation-page {
  min-height: 500px;
  padding-top: 50px;
}

.book-consultation-title {
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
}

.book-consultation-wrapper {
  padding: 0 80px;
}

.book-consultation-header {
  margin-top: 50px;
  height: 80px;
  background: #1332a1;
}

.book-consultation-header-title {
  color: white;
  font-size: 24px;
  line-height: 80px;
  text-align: center;
}

.book-consultation-content-wrapper {
  margin-top: 50px;
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 50px;
  justify-content: space-between;
}

.book-consultation-content .title {
  font-size: 22px;
  font-weight: bold;
}

.book-consultation-content {
  width: 350px;
}

.book-consultation-bottom {
  margin-top: 40px;
}

.book-appointment-btn {
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid rgb(0, 172, 215);
  background: rgb(0, 172, 215);
  color: white;
  cursor: pointer;
}

.book-consultation-detail-wrapper {
  width: 100%;
  height: 100%;
  font-size: 14px;
}

.book-consultation-detail-content-container {
  background: #f4f4f4;
  width: 100%;
  border-radius: 5px;
}

.book-consultation-detail-content-header {
  display: flex;
  margin-bottom: -15px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 92%;
  padding: 0 10px;
}

.book-consultation-detail-content-header-text {
  text-align: right;
  font-size: 12px;
}

.book-consultation-detail-content-header-image {
  text-align: right;
  font-size: 16px;
  padding: 0 20px;
}

.divider {
  background-color: gray;
  margin-top: 30px;
  margin-bottom: 10px;
}

.book-consultation-detail-content {
  padding: 0 20px;
  font-size: 12px;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

.book-consultation-detail-detail .title {
  font-size: 16px;
  font-weight: bolder;
  margin-top: 20px;
  margin-bottom: 15px;
}

.book-consultation-detail-detail .selected-type {
  display: flex;
  text-align: center;
}

.selected-type-label {
  margin: auto 0;
}

@media screen and (max-width: 480px) {
  .selected-type-label {
    display: none;
  }
}

.book-consultation-detail-detail .selected-type .selected-type-select {
  width: 300px;
  margin-left: -15px;
}

.time-consultation-btn-wrapper {
  display: flex;
  margin-top: 10px;
}

.time-consultation-date {
  background: #E6E8EC;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  line-height: 23px;
}

.time-consultation-btn {
  width: 140px;
  background: #E6E8EC;
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  border: 1px solid #E6E8EC;
}

.time-consultation-btn.selected {
  background: #1e5e72;
  color: white;
  border: 1px solid #1e5e72;
}

.time-consultation-btn:hover {
  border: 1px solid #1e5e72;
}

.time-consultation-btn .circle-icon {
  margin-top: auto;
  margin-bottom: auto;
  color: black;
  font-size: 15px;
}

.time-consultation-btn.selected .circle-icon {
  color: white;
}

.time-consultation-btn .text {
  margin: auto 0 auto 5px;
  font-weight: 500;
}

.appointment-step-container {
  background: #1e5e72;
  height: 40px;
  border-radius: 5px 5px 0px 0px;
}

.appointment-step-wrapper {
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 0 40px;
}

.appointment-step-container-step {
  color: #c2c2c2;
  margin: auto 0px;
  font-size: 12px;
  display: flex;
}

.appointment-step-container-step .circle-icon {
  font-size: 14px;
  margin: auto 0px;
}

.appointment-step-container-step .text {
  margin: auto 0px auto 5px;
}

.appointment-step-container-step.selected {
  color: white;
  font-weight: 500;
}

.appointment-list-wrapper {
  padding: 30px 10px 0px 30px;
  background: white;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  max-height: 500px;
  overflow: auto;
}

.no-appointment-list-wrapper {
  padding: 30px 20px 30px 20px;
  background: white;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  text-align: center;
}

.appointment-time-wrapper {
  margin-top: 10px;
  margin-left: 10px;
}

.appointment-list-list {
  color: black;
  /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%); */
}

.appointment-list-list:not(.first) {
  margin-top: 30px;
}

.appointment-list-list-top .icon-wrapper .icon {
  font-size: 50px;
  color: #cccccc;
}

.appointment-list-list-top .name {
  font-weight: 600;
  font-size: 20px;
  margin: 5px 10px 0 10px;
}

.appointment-list-list-top .time {
  text-wrap: nowrap;
  background: #5fc2b6;
  padding: 10px 15px;
  margin: 15px 0;
  min-width: 125px;
  height: 16px;
  border-radius: 5px;
  color: white;
  font-size: 3vw;
  cursor: pointer;
  text-align: center;
  transition: transform .2s;
}

.appointment-list-list-top .more_times_text {
  cursor: pointer;
  font-size: 14px;
  margin-left: 15px;
  color: black;
  font-weight: 700;
  margin-top: 2px;
}

.appointment-list-list-top .more_times_text:hover {
  cursor: pointer;
}

.more_times_text-icon {
  margin: 10px 0px 0px 10px;
}

.appointment-list-list-top .more_times_text-icon:hover {
  cursor: pointer;
}

.static-calendar-wrapper {
  background: rgb(249, 249, 249);
  display: flex;
  justify-content: center;
}

.static-calendar-container {
  padding: 20px;
}

.loading-appointment {
  height: 100px;
  background: #f9f9f9;
  padding-top: 20px;
}

.loading-appointment .circular-loading {
  text-align: center;
  height: 40px;
}

.loading-appointment .text {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
}

.date_list_container {
  display: flex;
  border-top: 1px solid #d3cdcd;
  border-radius: 5px;
  padding: 5px 0px 0px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.date_list_container:hover {
  color: #5fc2b6;
  border-radius: 5px;
}

.date_list_container-header {
  min-width: 75px;
  width: 75px;
  max-width: 75px;
  margin-top: 15px;
}

.check_date_list_container {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  padding: 0 50px;
  margin: 0 10px 20px 80px;
  flex-wrap: wrap;
}

.check_date_list_container-time {
  background: #5fc2b6;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  transition: transform .2s;
}

.continue-button {
  background: #5fc2b6;
  padding: 15px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: transform .2s;
  user-select: none ;
}

.continue-button-disabled {
  background: gray;
  padding: 15px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  user-select: none ;
}

.back-button {
  background: #5fc2b6;
  padding: 15px 10px;
  border-radius: 5px;
  color: white;
  font-size: 11px;
  cursor: pointer;
  margin-right: 10px;
  transition: transform .2s;
}

.continue-button-text {
  margin: 0 10px 0 10px;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
}

.navigation-buttons-wrapper {
  margin: 40px 0 0 0;
}

.confirm-detail-value {
  font-size: 14px;
  margin-bottom: 15px;
  margin-left: 2px;
  display: block;
}

.confirm-detail-item {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 700;
}

.book-consultation-detail-content-header-title {
  font-weight: bold;
  font-size: 16px;
}

.title-large {
  margin-top: 30px;
  font-size: 24px;
  font-weight: bolder;
}

.policies p {
  font-size: 16px;
  margin: 25px 0;
  line-height: 1.5em;
}

.fetch-progress {
  margin-bottom: 40px;
  text-align: center;
}

.powered-Wrapper {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.powered-Wrapper p {
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
}

.logo-small {
  max-height: 25px;
  margin: 7px 0 5px 10px;
}

.optometrist-image {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
}

.scroll-top {
  text-decoration: none;
}

.appointment-wrapper {
  margin: 20px 40px 20px 0px;
}

.appointment-wrapper .title {
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 15px;
  margin-top: 20px;
}

.detail-item-text {
  font-size: 16px;
  padding: 5px 0;
  line-height: 1.5em;
}

.left-table-column {
  padding-right: 10px;
}

a {
  text-decoration: none;
}

.confirmed {
  margin-top: 20px;
  margin-left: -25px;
  text-align: center;
  padding: 20px;
  background-color: lightgray;
}

.confirmed .header {
  font-size: 20px;
  font-weight: 500;
}

.confirm-text {
  font-size: 14px;
  line-height: 1.5em;
}

.confirm-table tr td{
  padding: 7px 5px;
}

.sidebar-detail {
  margin-top: 20px;
  line-height: 1.5em;
}

.detail-item-text-margin {
  margin-top: 10px;
  font-size: 16px;
  padding-bottom: 5px;
  padding-top: 10px;
}

.detail-item-text-small {
  margin-top: 40px;
  margin-left: -30px;
}

.filler-white {
  background-color: white;
  margin-left: -30px;
  width: 130%;
  height: 100%;
}

.filler-white-left {
  background-color: white;
  margin-left: -50px;
  width: 117%;
  height: 100%;
  margin-top: 20px;
}

.booking-confirmation-wrapper {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 10px 10px 20px grey;
}

@media (min-width: 480px) {

  .book-consultation-detail-detail .selected-type .selected-type-select {
    width: 300px;
    margin-left: 20px;
  }

  .appointment-time-wrapper {
    margin-top: 10px;
    display: flex;
  }

  .logo-default {
    min-height: 75px;
  }

  .book-consultation-detail-content-header-title {
    font-weight: bold;
    font-size: 20px;
  }

  .appointment-list-list-top .more_times_text {
    cursor: pointer;
    font-size: 16px;
    margin-left: 15px;
    line-height: 44px;
    color: black;
  }

  .date_list_container-header {
    min-width: 100px;
    width: 100px;
    max-width: 100px;
    margin-top: 15px;
    font-size: 14px;
  }

  .book-consultation-detail-content-header-text {
    text-align: right;
    font-size: 16px;
  }

  .book-consultation-detail-detail .title {
    font-size: 22px;
    font-weight: bolder;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .confirm-detail {
    margin-left: 20px;
  }

  .appointment-list-list-top .time {
    background: #5fc2b6;
    padding: 12px;
    max-width: 200px;
    border-radius: 5px;
    margin: 0;
    color: white;
    font-size: 14px;
    cursor: pointer;
  }

  .book-consultation-detail-wrapper {
    width: 100%;
    height: 100%;
    font-size: 14px;
  }

  .icon-wrapper {
    margin-left: 30px;
  }

  .appointment-list-list-top .name {
    font-weight: 600;
    font-size: 24px;
    margin: 5px 0px 0 20px;
  }

  .more_times_text-icon {
    margin: 0px 0px 0px 40px;
  }

  .appointment-wrapper {
    margin: 40px 20px 20px 20px;
  }

  .time-consultation-btn-wrapper {
    margin-top: 10px;
  }

  .booking-confirmation-wrapper {
    margin: auto;
  }

  .optometrist-image {
    height: 120px;
    width: 120px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  .confirm-detail-value {
    font-size: 16px;
    margin-bottom: 15px;
    margin-left: 10px;
    display: block;
  }

  .appointment-list-list-top .name {
    font-weight: 600;
    font-size: 20px;
    margin: 5px 10px 10px 20px;
  }

  .appointment-list-list-top .more_times_text {
    line-height: 34px;
  }

}

input:focus {
  border: 0;
}

.MuiButtonBase-root {
  font-size: 14px !important;
}

.MuiTypography-root {
  font-size: 14px !important;
}



.book-consultation-detail-content-wrapper {
  border-radius: 5px;
}

table {
	margin-bottom: 0 !important;
}

.sidebar tbody td {
  padding-top: 10px;
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
  color:rgb(0, 97, 157);
}

.modal-content p {
  text-align: left;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.back-button:hover, .continue-button:hover, 
.check_date_list_container-time:hover, .time:hover {
  transform: scale(1.05);
}

.field-row {
  display: flex;
  margin-bottom: 20px;
  border-top: 1px solid #d3cdcd;
  padding-top: 20px;
}

.select-view {
  margin: 20vh 20vw;
  padding: 20px 40px;
  border: 1px solid black;
  border-radius: 5px;
}

.option-button {
  background: #5fc2b6;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px 0 10px;
  width: 100px;
  text-align: center;
}

.submit {
  background: rgb(47, 116, 108);
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px 0 10px;
  width: 100px;
  text-align: center;
}

.detail-item-text {
  padding-top: 10px;
}

.error-page {
  padding: 30px;
  font-size: 20px;
  font-weight: 500;
}